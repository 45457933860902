import React from 'react'
import classes from "./Step.module.css"

const Step = (props) => {
  return (
    <div className={classes.container}>
        <div className={classes.step_heading}>{props.heading}</div>
        <div className={classes.content}>{props.details}</div>
    </div>
  )
}

export default Step