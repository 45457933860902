import React, { useEffect, useRef, useState } from 'react'
import classes from "./Home.module.css"
import Navbar from '../../components/Navbar/Navbar';
import Step from '../../components/Step/Step';

import Brand1 from "../../assets/images/brand1.svg"
import Brand2 from "../../assets/images/brand2.svg"
import Brand3 from "../../assets/images/brand3.svg"
import Brand4 from "../../assets/images/brand4.svg"
import Brand5 from "../../assets/images/brand5.svg"
import Illustration from "../../assets/images/illustration.svg"
import Star from "../../assets/images/star.svg"
import Halfstar from "../../assets/images/halfstar.svg"

import {Link, animateScroll as scroll} from "react-scroll"
import BacktoTop from "../../assets/images/backtotop.svg"

import {gsap} from "gsap"
import {ScrollTrigger} from "gsap/ScrollTrigger"
import Footer from '../../components/Footer/Footer';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
// import Pageclip from 'pageclip';


const content = [
  {
    heading: "1. Add Extension for Free",
    details: "A mere 2 click process: Search 'Couponshelf', Add Extension...and…ta-da.",
  },
  {
    heading: "2. Shop like you usually do",
    details: "Browse through your favorite sites and save on brands you love. We will take care of finding the coupons. It's completely automated.",
  },
  {
    heading: "3. Coupons will pop up",
    details: "The functioning codes will pop up on your screen. We'll apply the one that sets aside you the most cashback to your shopping basket. Cha-Ching! You are a certified smart shopper.",
  }
]

const brands = [
  {
    image: Brand1
  },
  {
    image: Brand2
  },
  {
    image: Brand3
  },
  {
    image: Brand4
  },
  {
    image: Brand5
  },
]

const reviews = [
  {
    rating: 4,
    name: "Ayush Mehta",
    review: "Couponshelf is a specially designed extension that will help you save money when you shop online. It's free and works with most of the major online retailers in India. It is easy to use and compatible with the majority of popular shopping browsers."
  },
  {
    rating: 4,
    name: "Somesh Nanda",
    review: "It's fine when they have discounts available, but they're few and far between; this isn't their fault, but rather the stores' failure to offer discounts in the first place!"
  },
  {
    rating: 5,
    name: "Divya Shah",
    review: "Online shopping is more popular than ever, and it has surpassed in-person shopping for many common purchases in many countries. Couponshelf is maximizing the use of big data and attempting to save as much money as possible for its users."
  },
]

const Home = () => {

  const [isVisible, setIsVisible] = useState(false);
  const [email, setEmail] = useState("");

  gsap.registerPlugin(ScrollTrigger);
  const ref = useRef(null);
  const refMob = useRef(null)
  const headingRef = useRef(null);

  // const pageclip = new Pageclip("api_72leCX7o98x4QX5SZZ6koIDIc0bUefFc");

  // useEffect(() => {
  //   gsap.fromTo("#animate", {
  //     scale: 1,
  //     opacity: 1,
  //   }, {
  //     scale: 5,
  //     opacity: 0,
  //     duration: 1,
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: "#animate1",
  //       scrub: true
  //     }
  //   })
  //   gsap.fromTo("#animate", {
  //     scale: 1,
  //     opacity: 1,
  //   }, {
  //     scale: 5,
  //     opacity: 0,
  //     duration: 1,
  //     ease: "none",
  //     scrollTrigger: {
  //       trigger: "#animate2",
  //       scrub: true
  //     }
  //   })
  // }, [])

  useEffect(() => {
    gsap.fromTo("#heading", {
      scale: 0,
      opacity: 0,
    }, {
      scale: 1.2,
      opacity: 1,
      duration: 1,
      scrollTrigger: {
        trigger: "#heading",
        scrub: true
      }
    })
  }, [])

  const toggleVisible = () => {
    if(window.scrollY > 300) {
      setIsVisible(true)
    } else {
      setIsVisible(false)
    }
  }
  window.addEventListener('scroll', toggleVisible);

  const changeEmail = (e) => {
    setEmail(e.target.value)
  }

  const notify = () => {
    console.log("Hello");
    // toast("We will get in touch with you shortly 😄")
    // const data = {
    //   email: "hello@ndsjn.sdvs"
    // }
    // PageClip.send("api_72leCX7o98x4QX5SZZ6koIDIc0bUefFc", data, function(error, response) {
    //   console.log("hdsmvnkjd");
    // })
  }

  return (
    <>
    <div className={classes.backtotop} onClick={scroll.scrollToTop} style={{display: isVisible ? "flex" : "none"}}>
      <img src={BacktoTop} alt="BacktoTop"/>
    </div>
    <Navbar isSmall={isVisible} />
    <div className={classes.container}>
      <div className={classes.main_container}>
        <div className={classes.left_content}>
          <div className={classes.heading}>
            Your own Cabin of Cashbacks
          </div>
          <div className={classes.content}>
            Love shopping but also want to save money, worry not. We offer you the one-click solution to scrimp and scrape your time and money by finding coupons for over 10000+ Indian websites. Go stack them all!
          </div>
          {/* <button className={classes.button}>
            Add to Chrome — it's Free
          </button> */}
          <form action="https://send.pageclip.co/P7IMpPzpcbpa6XeO7mkHvPsK086n0LX6" className={["pageclip-form", classes.form].join(" ")} method="post">
            <input type="email" name="email" value={email} className={[classes.button, classes.input].join(" ")} onChange={(e) => changeEmail(e)} placeholder="Enter your email "/>
            <button type="submit" className={[classes.button, "pageclip-form__submit"].join(" ")} onClick={() => notify()}>
              <span>Get Access</span>
            </button>
          </form>
          {/* <ToastContainer 
            position='bottom-center'
            autoClose={5000}
            hideProgressBar
            closeOnClick
            pauseOnHover
            bodyClassName={classes.toast}
            progressClassName={classes.toast}
            toastClassName={classes.toastclass}
            closeButton={false}
          /> */}
        </div>
        <div className={classes.right_animation}>
          <img src={Illustration} alt="Illustration" />
        </div>
      </div>
      <Link
        className={classes.how_it_works}
        to="container_coupon"
        smooth
        duration={500}
      >
        See how it works
      </Link>
    </div>
    <div className={classes.container_coupon} id="container_coupon">
      <div className={classes.upper_container}>
        <div className={classes.second_heading} id="heading" ref={headingRef}>See how it works</div>
        <div className={classes.flex_wrapper}>
          {
            content.map((step) => {
              return (
                <Step heading={step.heading} details={step.details}/>
              )
            })
          }
        </div>
        <button id="animate" className={classes.add_button}>Add to Chrome — It's Free</button>
      </div>
      <div className={classes.lower_container_mobile}>
        <Link
          className={classes.lower_container_mobile_link}
          to="container_stats"
          smooth
          duration={500}
          id="animate1"
          ref={refMob}
        >
          We have coupons for
        </Link>
      </div>
      <Link
        className={classes.lower_container}
        to="container_stats"
        smooth
        duration={500}
        id="animate2"
        ref={ref}
      >
        We have coupons for
      </Link>
    </div>
    <div className={classes.container_stats} id="container_stats">
      <div className={classes.upper_container_stats}>
        <div className={classes.second_heading}>Browse-Save-Smartshop over 10000+ Indian websites</div>
        <div className={classes.galary}>
          {
            brands.map((brand, i) => {
              return(
                <img className={classes.brandlogo} src={brand.image} alt={"brand "+ i} />
              )
            })
          }
        </div>
      </div>
      <div className={classes.lower_container_stats}>
        <div className={classes.flex}>
          <div className={classes.flex_heading}>₹20,000/Year</div>
          <div className={classes.flex_content}>Saving while shopping online *</div>
        </div>
        <div className={classes.flex}>
          <div className={classes.flex_heading}>3,000+</div>
          <div className={classes.flex_content}>Couponshelf Exclusive Members Coupons access *</div>
        </div>
        <div className={classes.flex}>
          <div className={classes.flex_heading}>20%</div>
          <div className={classes.flex_content}>Average discount on each order placed *</div>
        </div>
      </div>
    </div>
    <div className={classes.review_container}>
      <div className={classes.heading_review}>See what the users are saying</div>
      <div className={classes.review_flexbox}>
        {
          reviews.map((review) => {
            return (
              <div className={classes.review_flex}>
                <div className={classes.rating}>
                  <img src={Star} alt="star"/>
                  <img src={Star} alt="star"/>
                  <img src={Star} alt="star"/>
                  <img src={Star} alt="star"/>
                  <img src={Halfstar} alt="Halfstar"/>
                </div>
                <div className={classes.reviewer}>{review.name}</div>
                <div className={classes.review}>{review.review}</div>
              </div>
            )
          })
        }
      </div>
    </div>
    <Footer />
    </>
  )
}

export default Home;