import React from 'react'
import Back from "../../assets/images/back.svg"
import classes from "./Contactus.module.css"

import {useNavigate} from "react-router-dom"

const Contactus = () => {
  const navigate = useNavigate();
  return (
    <div style={{
        height: "100vh",
        backgroundColor: "#FFD646",
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        alignItems: "center",
    }}>
      <div
        className={classes.goback}
        onClick={() => navigate(-1)}
      >
        <img src={Back} alt="Back" style={{
          width:"30px",
        }}/>
        Go Back
      </div>
      <div style={{
        fontSize: "3rem"
      }}>
        Coming Soon
      </div>
  </div>
  )
}

export default Contactus