import React, { PureComponent } from 'react'
import { Route, BrowserRouter as Router, Routes } from "react-router-dom";
import Home from "./pages/Home/Home"
import CacheBuster from "./cacheBuster";
import Aboutus from "./pages/Aboutus/Aboutus"
import Contactus from "./pages/Contactus/Contactus"

class App extends PureComponent {
  render() {
    return (
      <CacheBuster>
        {({loading, isLatestVersion, refreshCacheAndReload}) => {
          if (loading) return null;
          if (!loading && !isLatestVersion) {
            alert("New Version Available!! Please refresh");
            refreshCacheAndReload();
          }
          return(
            <>
              <Router>
                <Routes>
                  <Route path='/' element={<Home />} />
                  <Route path='/aboutus' element={<Aboutus />} />
                  <Route path='/contactus' element={<Contactus />} />
                </Routes>
              </Router>
            </>
          )
        }}
      </CacheBuster>
    )
  }
}

export default App