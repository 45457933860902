import React from 'react'
import classes from "./Footer.module.css"

import Logo from "../../assets/images/logo_footer.svg"
import Mail from "../../assets/images/mail.svg"
import Instagram from "../../assets/images/instagram.svg"
import Twitter from "../../assets/images/twitter.svg"

const Footer = () => {
  return (
    <div className={classes.footer_container}>
      <div className={classes.footer_left}>
          <img src={Logo} alt="Logo" width="50px"/>
          2022 Couponshelf, LLC. All rights reserved.
      </div>
      <div className={classes.footer_right}>
        <img src={Twitter} alt="Twitter"/>
        <img src={Instagram} alt="Instagram"/>
        <img src={Mail} alt="Mail"/>
      </div>
    </div>
  )
}

export default Footer