import React, { useState } from 'react'
import classes from "./Navbar.module.css"

import Logo from "../../assets/images/logo.svg"
import Menu from "../../assets/images/menu.svg"
import Close from "../../assets/images/close.svg"

import {Link} from "react-router-dom"

const Navbar = (props) => {

  const [isOpen, setIsOpen] = useState(false);
  const openNavlinks = () => {
    setIsOpen(true)
  }
  const closeNavlinks = () => {
    setIsOpen(false)
  }
  return (
    <>
      <div className={classes.navbar_wrapper} style={{height: props.isSmall ? "10vh" : "12vh"}}>
        <img className={classes.logo} src={Logo} alt="Couponshelf" />
        <div className={classes.navlinks}>
          <div className={classes.navlink}>COUPONS FOR</div>
          <Link to="aboutus" className={classes.navlink}>ABOUT US</Link>
          <Link to="contactus" className={classes.navlink}>CONTACT US</Link>
        </div>
        <div className={classes.mobile_navlinks}>
          <img src={Menu} alt="Menu" onClick={openNavlinks} />
        </div>
      </div>
      <div className={classes.expanded_menu}
        style={{clipPath: isOpen ? "circle(2000px at 90% 10%)" : "circle(100px at 90% -20%)"
        }}  
      >
        <img className={classes.close_icon} src={Close} alt="Close" onClick={closeNavlinks}/>
        <div className={classes.navlink}>COUPONS FOR</div>
        <Link to="aboutus" className={classes.navlink}>ABOUT US</Link>
        <Link to="contactus" className={classes.navlink}>CONTACT US</Link>
      </div>
    </>
  )
}

export default Navbar